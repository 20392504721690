<mat-card>
  <mat-card-title>
    <span role="heading">Confirmed</span>
  </mat-card-title>
  <mat-card-content>
    <div>
      <label>
        <strong>Approved Tally*</strong>
      </label>
      <div>
        <strong>{{ pickup.booking.approvedNumOfCalves }}</strong>
      </div>
    </div>
    <div>
      <label>
        <strong>Pick-up Date</strong>
      </label>
      <div>
        {{ date }}
      </div>
    </div>
    <div>
      <label>
        <strong>Requested By</strong>
      </label>
      <div>
        {{ pickup.booking.requestedBy }}
      </div>
    </div>
    <div class="panel-info-text">
      *Approved tallies are subject to change due to unforeseen events e.g. weather event
    </div>
    <div *ngIf="pickup.declarationExpected" class="declaration" >
      <div>
        <mat-label>Pick-up Declaration will appear here once available</mat-label>
      </div>
    </div>
  </mat-card-content>
</mat-card>
