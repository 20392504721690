import { createReducer, on } from "@ngrx/store";
import { Integrations, Model } from "../interfaces";
import {
  approveIntegrationsCompleted,
  loadIntegrationsCompleted,
  revokeIntegrationsCompleted,
} from "./actions";

export const integrationsFeatureKey = "integrations";

const initialState: Integrations.State = {
    integrations: [],
}

export const integrationsReducer = createReducer<Integrations.State>(
    initialState,

    on(
      loadIntegrationsCompleted,
      (state: Integrations.State, res): Integrations.State => ({
        ...state,
        integrations: res.integrations.slice(),
      })
    ),

    on(
      approveIntegrationsCompleted,
      (state: Integrations.State, res): Integrations.State => ({
        ...changeState(state, res.id, true, false),
      })
    ),

    on(
      revokeIntegrationsCompleted,
      (state: Integrations.State, res): Integrations.State => ({
        ...changeState(state, res.id, false, true),
      })
    ),
);

function changeState(
  state: Integrations.State,
  id: number,
  approved: boolean,
  revoked: boolean,
): Integrations.State {
  const changedIntegration: Model.AdviceSharingPermissionResponse = state.integrations
    .find(integration => integration.id === id);
  const unchangedIntegrations: Model.AdviceSharingPermissionResponse[] = state.integrations
    .filter(integration => integration.id !== id);

  return {
    integrations: unchangedIntegrations.concat({
      ...changedIntegration,
      approved: approved,
      revoked: revoked,
    }),
  }
}