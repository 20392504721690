import { createFeatureSelector, createSelector } from "@ngrx/store";
import { Integrations } from "../interfaces";
import { integrationsFeatureKey } from "./reducer";

const getIntegrationsState = createFeatureSelector<Integrations.State>(integrationsFeatureKey);

export const selectConfirmedIntegrations = createSelector(
    getIntegrationsState,
    (state: Integrations.State) => state.integrations
        .filter(integration => integration.approved)
        .sort((integrA, integrB) => integrA.requesterName.localeCompare(integrB.requesterName)),
);

export const selectUnhandledIntegrations = createSelector(
    getIntegrationsState,
    (state: Integrations.State) => state.integrations
        .filter(integration => !integration.approved && !integration.revoked)
        .sort((integrA, integrB) => integrA.requesterName.localeCompare(integrB.requesterName)),
);