<table mat-table
       multiTemplateDataRows
       [dataSource]="integrations">
    <ng-container matColumnDef="requesterName">
        <th mat-header-cell *matHeaderCellDef>3rd Party Name</th>
        <td mat-cell *matCellDef="let integration">
            <div class="sub-row">
                <p>{{ integration.requesterName }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="supplierCode">
        <th mat-header-cell *matHeaderCellDef>Supplier Code</th>
        <td mat-cell *matCellDef="let integration">
            <div class="sub-row">
                <p>{{ integration.anzcoSupplierId }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="supplierName">
        <th mat-header-cell *matHeaderCellDef>Supplier Name</th>
        <td mat-cell *matCellDef="let integration">
            <div class="sub-row">
                <p>{{ integration.tradingName }}</p>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>Action</th>
        <td mat-cell *matCellDef="let integration">
            <button class="mat-raised-button decline-button"
                    (click)="revokeAccess(integration.id)">
                    Revoke Access
            </button>
        </td>
    </ng-container>

    <ng-container matColumnDef="properties">
        <td mat-cell *matCellDef="let integration" [attr.colspan]="4">
            <div
                *ngFor="let property of integration.properties"
                class="sub-row property">
                Property: {{property.propertyName}}
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let integration; columns: tableColumns"></tr>
    <tr
        mat-row
        *matRowDef="let _; columns: ['properties']"
        class="property-detail-row">
    </tr>
</table>