import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { DefaultNavigationComponent } from './default-navigation.component';
import { BrowserUtils } from '@azure/msal-browser';
import * as features from '../features/index';
import { CanDeactivateStaffManagement } from '../features/index';
import { AppContentComponent } from '../app-content.component';
import { BobbyCalvesComponent } from '../features/bobby-calves/bobby-calves.component';
import { BobbyCalvesCalendarComponent } from '../features/bobby-calves/bobby-calves-calendar/bobby-calves-calendar.component';

export const routes: Routes = [
  {
    path: '',
    component: DefaultNavigationComponent,
  },
  {
    path: '',
    component: AppContentComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'auth', // route for MSAL to handle redirects
        component: MsalRedirectComponent,
      },
      {
        path: features.homeRoute,
        component: features.HomepageShellComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.moreRoute,
        component: features.MoreComponent,
        canActivate: [MsalGuard],
        canDeactivate: [CanDeactivateStaffManagement],
      },
      {
        path: features.allAdvicesRoute,
        component: features.AllAdvicesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.singleAdviceRoute,
        component: features.SingleAdviceComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.allStaffManagementRoute,
        component: features.AllStaffManagementComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.staffManagementRoute,
        component: features.StaffManagementComponent,
        canActivate: [MsalGuard],
        canDeactivate: [CanDeactivateStaffManagement],
      },
      {
        path: features.roleDescriptionRoute,
        component: features.RolesDescriptionComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.registerRoute,
        component: features.RegistrationShellComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.registrationHelpRoute,
        component: features.RegistrationHelpComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.noPropertiesRoute,
        component: features.NoPropertiesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.reportsRoute,
        component: features.ReportsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.complianceRoute,
        component: features.ComplianceComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.documentsRoute,
        component: features.DocumentsComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.newsOverviewRoute,
        component: features.NewsOverviewShellComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.newsItemRoute,
        component: features.NewsItemComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.newsFeedRoute,
        component: features.NewsFeedShellComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.FAQRoute,
        component: features.FaqComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.legalRoute,
        component: features.LegalComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.bobbyCalvesProfileRoute,
        component: BobbyCalvesComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.bobbyCalvesCalendarRoute,
        component: BobbyCalvesCalendarComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.pickupDeclarationsRoute,
        component: features.PickupDeclarationComponent,
        canActivate: [MsalGuard],
      },
      {
        path: features.integrationsRoute,
        component: features.IntegrationsComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: features.AdvantageRedirectRoute,
    component: features.AdvantageRedirectComponent,
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabled'
          : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
