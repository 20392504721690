import { Injectable } from "@angular/core";
import { Model } from "../admin.interfaces";
import { environment } from '../../../environments/environment';
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { loadUserByIdStarted, loadUserByIdCompleted } from "../state/actions";
import { Router } from "@angular/router";
import { adminRoute, portalUserRoute } from "../constants";

@Injectable({ providedIn: 'root' })
export class CommandGetUserById {
    constructor(
        private readonly store: Store,
        private readonly http: HttpClient,
        private readonly router: Router,
    ) {}

    public async retrieve(userId: number): Promise<void> {
        this.store.dispatch(loadUserByIdStarted());
        const userByIdResponse: Model.UserByIdResponse = await this.requestUserDetails(userId);
        const user: Model.User = {
            userId: userByIdResponse.userId,

            email: userByIdResponse.email,
            firstName: userByIdResponse.firstName,
            gstNumber: userByIdResponse.gstNumber,
            isActive: userByIdResponse.isActive,
            surName: userByIdResponse.surName,
            contactNumber: userByIdResponse.contactNumber,
            advantageUsername: userByIdResponse.advantageUsername,

            producerCode: "",
            producerName: "",
            role: null,
        };

        this.store.dispatch(loadUserByIdCompleted({
            user,
            userByIdResponse,
        }));
        this.router.navigate([adminRoute, portalUserRoute], {
            queryParams: { userId },
        });
    }

    private async requestUserDetails(userId: number): Promise<Model.UserByIdResponse> {
        return await this.http
            .get<Model.UserByIdResponse>(environment.apiUrl + "/api/admin/user-by-id/", { params: { userId } })
            .toPromise();
    }
}