<mat-card
  *ngFor="let integration of integrations"
  class="card-border">

  <mat-card-content>
    <div class="header">
      <div class="emphasis front-label">{{ integration.requesterName }}</div>
      <button class="mat-raised-button decline-button"
              (click)="revokeAccess(integration.id)">
              Revoke Access
      </button>
    </div>
    <div class="rows">
      <div>
        <span><strong> Supplier Code</strong></span>
        <div>{{ integration.anzcoSupplierId }}</div>
      </div>
      <div>
        <span><strong> Supplier Name</strong></span>
        <div>{{ integration.tradingName }}</div>
      </div>
      <div
          *ngFor="let property of integration.properties"
          class="sub-row property">
          Property: {{property.propertyName}}
      </div>
    </div>
  </mat-card-content>
</mat-card>
