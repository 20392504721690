import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";
import { Selector } from "../state/selector";
import { Model, Projection } from "../admin.interfaces";
import { CommandSearchProperties } from "../commands/command-search-properties";
import { editUserProducerCodeSearchUpdated, editUserPropertySearchUpdated, editUserPropertyRoleUpdated, editUserUpdated, propertyLockChanged } from "../state/actions";
import { Role, RoleEnum } from "src/app/miscellaneous/enums/role.enum";
import { EventInputDelay } from "src/app/miscellaneous/event-input-delay";

@Component({
    selector: "app-edit-user",
    templateUrl: "./edit-user.component.html",
    styleUrls: ["../admin.scss", "./edit-user.component.scss"],
})
export class AdminEditUserComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() displayProducerData: boolean = true;

    public user: Projection.EditUser;
    public disabled: boolean = false;
    public validAdvantageEmail: boolean = true;
    public properties: Observable<Projection.PropertyRole[]>;
    public producerCodeKnown: Observable<boolean>;
    public allRoles = RoleEnum.all();
    public notificationSettings: Model.UserNotificationSettings = null;

    private subscriptions: Subscription[];
    private producerCodeSearchTerm: string;
    private propertySearchTerm: string;

    constructor(
        private readonly store: Store,
        private readonly selector: Selector,
        private readonly eventInputDelay: EventInputDelay,
        private readonly commandSearchProperties: CommandSearchProperties,
    ) { }

    public ngOnInit(): void {
        this.properties = this.store.select(this.selector.selectEditUserPropertyRoles);
        this.producerCodeKnown = this.store.select(this.selector.selectEditUserProducerCodeKnown);
        this.subscriptions = [
            this.store.select(this.selector.selectEditUser).subscribe(
                (user: Projection.EditUser) => {
                    this.user = user;
                    this.notificationSettings = user;

                    this.commandSearchProperties.execute(
                        this.user.userId,
                        this.user.producerCode,
                        null,
                    );
            }),
            this.store.select(this.selector.selectEditUserProducerCodeSearch).subscribe(
                (producerCode: string) => this.producerCodeSearchTerm = producerCode),
            this.store.select(this.selector.selectEditUserPropertySearch).subscribe(
                (property: string) => this.propertySearchTerm = property),
            this.store.select(this.selector.selectEditUserDisabled).subscribe(
                (disabled: boolean) => this.disabled = disabled),
            this.store.select(this.selector.selectValidAdvantageEmail).subscribe(
                (valid: boolean) => this.validAdvantageEmail = valid),
        ];
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.subscriptions = [];
    }

    public changePropertySearchTerm(change: string): void {
        this.store.dispatch(editUserPropertySearchUpdated({ change }));
        this.searchProperties();
    }

    public changeProducerCodeSearchTerm(change: string): void {
        this.store.dispatch(editUserProducerCodeSearchUpdated({ change }));
        this.searchProperties();
    }

    public change(change: Partial<Model.AdminTask>): void {
        this.store.dispatch(editUserUpdated({ change }));
    }

    public changeLock(propertyId: number): void {
        this.store.dispatch(propertyLockChanged({ propertyId }));
    }

    public setRole(propertyId: number, role: Role): void {
        this.store.dispatch(editUserPropertyRoleUpdated({ propertyId, role }));
    }

    private searchProperties(): void {
        this.eventInputDelay.delay(async () => {
            await this.commandSearchProperties.execute(
                this.user.userId,
                this.producerCodeSearchTerm,
                this.propertySearchTerm && this.propertySearchTerm.length > 2 ?
                    this.propertySearchTerm :
                    null,
            );
        });
    }
}