import { createAction, props } from '@ngrx/store';
import { Model } from '../interfaces';

export const loadIntegrations = createAction(
    "[Integrations] Load Integrations Started",
);

export const loadIntegrationsCompleted = createAction(
    "[Integrations] Load Integrations Completed",
    props<{ integrations: Model.AdviceSharingPermissionResponse[] }>(),
);

export const approveIntegrations = createAction(
    "[Integrations] Approve Integrations Started",
    props<{ id: number }>(),
);

export const approveIntegrationsCompleted = createAction(
    "[Integrations] Approve Integrations Completed",
    props<{ id: number }>(),
);

export const revokeIntegrations = createAction(
    "[Integrations] Revoke Integrations Started",
    props<{ id: number }>(),
);

export const revokeIntegrationsCompleted = createAction(
    "[Integrations] Revoke Integrations Completed",
    props<{ id: number }>(),
);