<div class="default-container">
   <mat-card class="default-card">
      <form [formGroup]="completeRegistrationForm" (ngSubmit)="submit()">
      <mat-card-title class=><h2>Complete Registration</h2></mat-card-title>
      
         <mat-card-content>
            <div class="form-row">
               <mat-form-field appearance="fill">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName" cy-data="firstName"/>
                  <mat-error *ngIf="completeRegistrationForm.controls.firstName.hasError('required')">First Name is required</mat-error>
               </mat-form-field>
               <mat-form-field appearance="fill">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="surname" cy-data="lastName"/>
                  <mat-error *ngIf="completeRegistrationForm.controls.surname.hasError('required')">Last Name is required</mat-error>
               </mat-form-field>
            </div>
            <div class="form-row">
               <mat-form-field appearance="fill">
                  <mat-label>Trade Name</mat-label>
                  <input matInput formControlName="producer" cy-data="tradeName"/>
                  <mat-error *ngIf="completeRegistrationForm.controls.producer.hasError('required')">Trade Name is required</mat-error>
               </mat-form-field>
               <mat-form-field appearance="fill">
                  <mat-label>Producer Code</mat-label>
                  <input matInput formControlName="producerCode" cy-data="producerCode"/>
                  <mat-icon class="non-mobile" matSuffix matTooltip="You can find your code on your invoice in the Creditor Reference field">help</mat-icon>
                  <mat-error *ngIf="completeRegistrationForm.controls.producerCode.hasError('required')">Producer Code is required</mat-error>
               </mat-form-field>
            </div>
            <div class="form-row">
               <mat-form-field appearance="fill">
                  <mat-label>Select Role</mat-label>
                  <mat-select formControlName="role" cy-data="selectRole">
                     <mat-option *ngFor="let role of allRoles" [value]="role.role" cy-data=roleSelectOptions>
                     {{role.description}}
                     </mat-option>
                  </mat-select>
                  <mat-error *ngIf="completeRegistrationForm.controls.role.hasError('required')">Role is required</mat-error>
               </mat-form-field>
               <mat-form-field appearance="fill">
                  <mat-label>GST</mat-label>
                  <input matInput class="number-input-plain" formControlName="gstNumber" placeholder="999999999" cy-data="gst"/>
                  <mat-icon class="non-mobile" matSuffix matTooltip="GST Number can only contain digits and must be 9 digits">help</mat-icon>
                  <!-- TODO tidier error handling -->
                  <mat-error *ngIf="completeRegistrationForm.controls.gstNumber.hasError('required') || completeRegistrationForm.controls.gstNumber.hasError('pattern') ">
                     GST number should be 9 digits
                  </mat-error>
               </mat-form-field>
            </div>
            
            <div>
               <mat-error *ngIf="!completeRegistrationForm.controls.acceptTermsConditions.value && submitClicked">Please accept the Terms & Conditions</mat-error>
               <mat-checkbox formControlName="acceptTermsConditions" class="wrap-text" cy-data="acceptTermsConditions">
                  <span class="text-wrap" >
                     I have read and accepted the <a href="https://anzcofoods.com/website-terms-of-use/">Terms & Conditions</a>*
                  </span>
               </mat-checkbox>
            </div>
            
            <div>
               <mat-checkbox formControlName="receivePromotionalMaterial" class="wrap-text" cy-data="receivePromotionalMaterial"><span class="text-wrap" >I wish to receive promotional emails from ANZCO Foods</span></mat-checkbox>
            </div>
         </mat-card-content>
         
         <mat-card-actions>
            <button mat-raised-button color="accent" type="submit" cy-data="registerBtn">Register</button>
         </mat-card-actions>
      
      </form>
   </mat-card>
</div>