<div mat-dialog-title>
    <h1>User Activity History</h1>
</div>

<mat-dialog-content>
    <table mat-table
        [dataSource]="history">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="short-text-column">Date & Time</th>
            <td mat-cell *matCellDef="let historyItem">
                <div>
                    <p>{{ historyItem.created | date: "dd/MM/yyyy HH:mm" }}</p>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="environment">
            <th mat-header-cell *matHeaderCellDef class="short-text-column">Medium</th>
            <td mat-cell *matCellDef="let historyItem">
                <div>
                    <p *ngIf="historyItem.environment === 1">Android Phone App</p>
                    <p *ngIf="historyItem.environment === 2">iPhone App</p>
                    <p *ngIf="historyItem.environment === 3">WEB Browser</p>
                    <p *ngIf="historyItem.environment === 4">Unknown</p>
                    <p *ngIf="historyItem.environment === 11">Mobile App</p>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="feature">
            <th mat-header-cell *matHeaderCellDef class="grow">Action</th>
            <td mat-cell *matCellDef="let historyItem" class="grow">
                <div>
                    <p *ngIf="historyItem.feature === 0">Successful Login</p>
                    <p *ngIf="historyItem.feature === 1">Visited Advice Page</p>
                    <p *ngIf="historyItem.feature === 3">Downloaded Invoice</p>
                    <p *ngIf="historyItem.feature === 4">Downloaded Killsheet</p>
                    <p *ngIf="historyItem.feature === 5">Downloaded Advice CSV</p>
                    <p *ngIf="historyItem.feature === 6">Downloaded Financial Report </p>
                    <p *ngIf="historyItem.feature === 7">Looked Up Staff</p>
                    <p *ngIf="historyItem.feature === 8">Added New Staff Member</p>
                    <p *ngIf="historyItem.feature === 9">Changed Staff Details</p>
                    <p *ngIf="historyItem.feature === 10">Removed Staff</p>
                    <p *ngIf="historyItem.feature === 20">Visited Bobby Calves Screen</p>
                    <p *ngIf="historyItem.feature === 21">Created Bobby Calves Booking</p>
                    <p *ngIf="historyItem.feature === 22">Created Declaration</p>
                </div>
            </td>
        </ng-container>
        
        <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef class="short-text-column">Details</th>
            <td mat-cell *matCellDef="let historyItem">
                <div>
                    <p>{{ historyItem.data }}</p>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['date', 'environment', 'feature', 'data']; sticky: true"></tr>
        <tr mat-row *matRowDef="let historyItem; columns: ['date', 'environment', 'feature', 'data']"></tr>
    </table>

    <div *ngIf="history.length === 0" class="no-results">This user hasn't logged into the Producer Portal yet.</div>

    <mat-paginator *ngIf="history.length > 0"
                    [length]="totalHistoryitems"
                    [pageIndex]="pageNumber"
                    [pageSize]="10"
                    [pageSizeOptions]="[10]"
                    [showFirstLastButtons]="true"
                    (page)="onPage($event)"
                    aria-label="Select page">
    </mat-paginator>
</mat-dialog-content>

<mat-dialog-actions>
    <button class="mat-raised-button mat-primary" (click)="cancel()">Back</button>
</mat-dialog-actions>