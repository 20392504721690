<div class="default-container" data-cy="processing-page-shell">
  <div class="headline">
    <h2>More</h2>
  </div>

  <div class="menu-items">
    <mat-card (click)="goNews()">
      <div class="menu-header">
        <mat-icon>newspaper</mat-icon>
        <span>News</span>
      </div>
      <div class="menu-description">
        <span>Contains the latest</span>
        <span>news articles from</span>
        <span>ANZCO Foods</span>
      </div>
    </mat-card>

    <mat-card *ngIf="shouldDisplayOwnerMenu" (click)="goStaff()">
      <div class="menu-header">
        <mat-icon>person</mat-icon>
        <span>Staff</span>
      </div>
      <div class="menu-description">
        <span>Manage all your Staff</span>
        <span>in one place. Add, edit</span>
        <span>or remove staff</span>
        <span>member access from</span>
        <span>your property</span>
      </div>
    </mat-card>

    <mat-card *ngIf="shouldDisplayDocumentsMenu" (click)="goDocuments()">
      <div class="menu-header">
        <mat-icon>article</mat-icon>
        <span>Docs</span>
      </div>
      <div class="menu-description">
        <span>View digital bobby</span>
        <span>calf declaration history</span>
      </div>
    </mat-card>

    <mat-card *ngIf="shouldDisplayIntegrationMenu" (click)="goIntegrations()">
      <div class="menu-header">
        <mat-icon>sync_lock</mat-icon>
        <span>External</span>
        <span>Setup</span>
      </div>
      <div class="menu-description">
        <span>3rd Party</span>
        <span>Data Sharing</span>
      </div>
    </mat-card>

    <mat-card (click)="logout()">
      <div class="menu-header">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </div>
      <div class="menu-description">
        <span>Takes you back to the</span>
        <span>Sign-in screen</span>
      </div>
    </mat-card>
  </div>
</div>