import { Injectable } from "@angular/core";
import { createSelector } from "@ngrx/store";
import { Role, RoleEnum } from "src/app/miscellaneous/enums/role.enum";
import { newRequestsRoute, portalUsersRoute } from "../constants";
import { Model, Projection } from "../admin.interfaces";

@Injectable({ providedIn: 'root' })
export class Selector {
    public selectAdminTasks = (state: Model.State) => state.admin.adminTasks;

    public selectEditUserModel = (state: Model.State) => state.admin.editUser;
    public selectEditUserNotifications = (state: Model.State) => state.admin.editUserNotifications;
    public selectEditUserPropertyRolesModels = (state: Model.State) => state.admin.editUserPropertyRoles;
    public selectEditUserProducerCodeKnown = (state: Model.State) => state.admin.editUserProducerCodeKnown;
    public selectEditUserPropertySearch = (state: Model.State) => state.admin.editUserPropertySearch;
    public selectEditUserProducerCodeSearch = (state: Model.State) => state.admin.editUserProducerCodeSearch;
    public selectEditUserDisabled = (state: Model.State) => state.admin.editUserDisabled;
    public selectEditUserConfirmed = (state: Model.State) => state.admin.editUserConfirmed;
    public selectEditPortalUsersSearch = (state: Model.State) => state.admin.editPortalUsersSearch;
    public selectEditPortalUsersHistoryTotal = (state: Model.State) => state.admin.editUserHistoryTotol;
    public selectEditPortalUsersHistory = (state: Model.State) => state.admin.editUserHistory;

    public selectEditAdminTaskId = (state: Model.State) => state.admin.editAdminTaskId;
    public selectEditDeclineReason = (state: Model.State) => state.admin.editDeclineReason;
    public selectEditDeclineReasonText = (state: Model.State) => state.admin.editDeclineReasonText;
    private selectEditPortalUsersSearchResults = (state: Model.State) => state.admin.editPortalUsersSearchResults;

    public selectNewUserAdminTasks = createSelector(
        this.selectAdminTasks,
        (tasks: Model.AdminTask[]) => {
            const result: Projection.AdminTask[] = this.orderAndMap(tasks.filter(task => task.isNewUser));
            return result;
        },
    );

    public selectChangeUserAdminTasks = createSelector(
        this.selectAdminTasks,
        (tasks: Model.AdminTask[]) => {
            const result: Projection.AdminTask[] = this.orderAndMap(tasks.filter(task => !task.isNewUser));
            return result;
        },
    );

    public selectAmountNewUserAdminTasks = createSelector(
        this.selectNewUserAdminTasks,
        (tasks: Projection.AdminTask[]) => tasks.length,
    );

    public selectAmountChangeUserAdminTasks = createSelector(
        this.selectChangeUserAdminTasks,
        (tasks: Projection.AdminTask[]) => tasks.length,
    );

    public selectShowAmountNewUserAdminTasks = createSelector(
        this.selectAmountNewUserAdminTasks,
        (amount: number) => amount > 0,
    );

    public selectShowAmountChangeUserAdminTasks = createSelector(
        this.selectAmountChangeUserAdminTasks,
        (amount: number) => amount > 0,
    );

    public selectSubPage = createSelector(
        this.selectAdminTasks,
        (tasks: Model.AdminTask[]) => {
            const result: string = tasks.length == 0 ? portalUsersRoute : newRequestsRoute;
            return result;
        },
    );

    public selectEditUser = createSelector(
        this.selectEditUserModel,
        this.selectEditUserNotifications,
        (user: Model.User, notifications: Model.UserNotificationSettings) => {
            const result: Projection.EditUser = {
                userId: user.userId,
                email: user.email,
                firstName: user.firstName,
                isActive: user.isActive,
                producerCode: user.producerCode,
                producerName: user.producerName,
                role: RoleEnum.parse(user.role),
                surName: user.surName,
                gstNumber: user.gstNumber,
                contactNumber: user.contactNumber,
                advantageUsername: user.advantageUsername,
                receiveAndroidNotifications: notifications ?
                    notifications.receiveAndroidNotifications :
                    false,
                receiveIosNotifications: notifications ?
                    notifications.receiveIosNotifications :
                    false,
                notifyOnWeights: notifications ?
                    (notifications.receiveAndroidNotifications || notifications.receiveIosNotifications) && notifications.notifyOnWeights :
                    false,
                notifyOnPricing:  notifications ?
                    (notifications.receiveAndroidNotifications || notifications.receiveIosNotifications) && notifications.notifyOnPricing :
                    false,
                notifyOnBobbyCalves:  notifications ?
                    (notifications.receiveAndroidNotifications || notifications.receiveIosNotifications) && notifications.notifyOnBobbyCalves :
                    false,
            };
            return result;
        }
    );

    public selectEditUserPropertyRoles = createSelector(
        this.selectEditUserPropertyRolesModels,
        this.selectEditUserDisabled,
        (propertyRoles: Model.PropertyRole[], editUserDisabled: boolean) => {
            const result = propertyRoles.slice()
                .sort((propertyRoleA: Model.PropertyRole, propertyRoleB: Model.PropertyRole) => {
                    if (propertyRoleA.role !== Role.Unknown && propertyRoleB.role === Role.Unknown) {
                        return -1;
                    }
                    if (propertyRoleA.role === Role.Unknown && propertyRoleB.role !== Role.Unknown) {
                        return 1;
                    }
                    return propertyRoleA.propertyId - propertyRoleB.propertyId;
                })
                .map(model => {
                    const projection: Projection.PropertyRole = {
                        locked: model.locked,
                        propertyId: model.propertyId,
                        propertyName: model.propertyName,
                        role: model.role,
                        roleDescription: RoleEnum.parse(model.role),
                        shouldDisplayRoleChoice: !model.locked && !editUserDisabled,
                        shouldDisplayPlainRole: model.locked || editUserDisabled,
                        shouldDisplayLock: !model.locked && !editUserDisabled && model.role !== Role.Unknown,
                        shouldDisplayUnlock: model.locked && !editUserDisabled,
                    };

                    return projection;
                });

            return result;
        }
    );

    public selectValidAdvantageEmail = createSelector(
        this.selectEditUserModel,
        (user: Model.User) => 
            (user.advantageUsername || null) === null ||
            new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")
                .test((user.advantageUsername || "").trim())
    );

    public selectValidToConfirm = createSelector(
        this.selectEditUserPropertyRolesModels,
        this.selectValidAdvantageEmail,
        (propertyRoles: Model.PropertyRole[], validEmail: boolean) => propertyRoles.some(role => role.locked && validEmail),
    );

    public selectUserSearchResult = createSelector(
        this.selectEditPortalUsersSearchResults,
        (userSearchResult: Model.UserSearchResult[]) =>
            userSearchResult.slice().sort((userA: Model.UserSearchResult, userB: Model.UserSearchResult) => {
                if (userA.isActive && !userB.isActive) {
                    return -1;
                }
                if (!userA.isActive && userB.isActive) {
                    return 1;
                }
                return userA.email.localeCompare(userB.email);
            }),
    );

    private orderAndMap(tasks: Model.AdminTask[]): Projection.AdminTask[] {
        return tasks
            .sort((taskA: Model.AdminTask, taskB: Model.AdminTask) => {
                if (taskA.assignedTo === null && taskB.assignedTo !== null) {
                    return -1;
                }
                if (taskA.assignedTo !== null && taskB.assignedTo === null) {
                    return 1;
                }
                if (taskA.createdDateYear < taskB.createdDateYear) {
                    return -1;
                }
                if (taskA.createdDateYear > taskB.createdDateYear) {
                    return 1;
                }
                if (taskA.createdDateMonth < taskB.createdDateMonth) {
                    return -1;
                }
                if (taskA.createdDateMonth > taskB.createdDateMonth) {
                    return 1;
                }
                if (taskA.createdDateDay < taskB.createdDateDay) {
                    return -1;
                }
                if (taskA.createdDateDay > taskB.createdDateDay) {
                    return 1;
                }
                if (taskA.createdTimeHour < taskB.createdTimeHour) {
                    return -1;
                }
                if (taskA.createdTimeHour > taskB.createdTimeHour) {
                    return 1;
                }
                if (taskA.createdTimeMinutes < taskB.createdTimeMinutes) {
                    return -1;
                }
                if (taskA.createdTimeMinutes > taskB.createdTimeMinutes) {
                    return 1;
                }
                return 0;
            })
            .map(task => this.toTaskProjection(task));
    }

    private toTaskProjection(task: Model.AdminTask): Projection.AdminTask {
        const projection: Projection.AdminTask = {
            id: task.id,
            userId: task.userId,

            assignedTo: task.assignedTo,
            created:
                task.createdDateDay + "/" +
                task.createdDateMonth + "/" +
                task.createdDateYear + " at " +
                task.createdTimeHour + ":" +
                (task.createdTimeMinutes < 10 ?
                    "0" + task.createdTimeMinutes : 
                    task.createdTimeMinutes),
            email: task.email,
            producerCode: task.producerCode,
            producerName: task.producerName,
            role: RoleEnum.parse(task.role),
            userName:
                task.firstName +
                (task.firstName && task.surName ? " " : "") +
                task.surName,
        };

        return projection;
    }
}