import { Component, Input } from "@angular/core";
import { Model } from "../interfaces";
import { Store } from "@ngrx/store";
import { revokeIntegrations } from "../state/actions";
import { ConfirmationDialogComponent } from "../../confimation-dialog";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-integrations-cards",
    templateUrl: "integrations-cards.component.html",
    styleUrls: ["integrations-cards.component.scss"]
})
export class IntegrationsCardsComponent {
    @Input() public integrations: Model.AdviceSharingPermissionResponse[] = [];

    constructor(
        private readonly store: Store,
        private readonly dialog: MatDialog,
    ) { }

    public revokeAccess(id: number): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Remove Access",
                text: "Are you sure you want to remove the data sharing access?",
            },
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
                this.store.dispatch(revokeIntegrations({ id }))
            }
        });
    }
}