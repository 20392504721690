import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Model } from './interfaces';
import {
  selectConfirmedIntegrations,
  selectUnhandledIntegrations,
} from './state/selectors';
import {
  approveIntegrations,
  loadIntegrations,
  revokeIntegrations,
} from './state/actions';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject();
  public confirmedIntegrations: Model.AdviceSharingPermissionResponse[] = [];
  public unhandledIntegrations: Model.AdviceSharingPermissionResponse[] = [];
  public totalDeclarations: number = 0;

  constructor(
    private readonly store: Store,
  ) { }
 
  public ngOnInit(): void {
    this.store.select(selectUnhandledIntegrations)
      .pipe(takeUntil(this.destroy$))
      .subscribe((integrations) => this.unhandledIntegrations = integrations);

    this.store.select(selectConfirmedIntegrations)
      .pipe(takeUntil(this.destroy$))
      .subscribe((integrations) => this.confirmedIntegrations = integrations);

    this.store.dispatch(loadIntegrations());
  }

  public approve(id: number): void {
    this.store.dispatch(approveIntegrations({ id }))
  }

  public deny(id: number): void {
    this.store.dispatch(revokeIntegrations({ id }))
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}