import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { integrationsReducer, integrationsFeatureKey } from './state/reducer';
import { IntegrationsEffects } from './state/effects';
import { AngularMaterialModule } from '../../miscellaneous';
import { IntegrationsComponent } from './integrations.component';
import { IntegrationsTableComponent } from './integrations-table/integrations-table.component';
import { IntegrationsCardsComponent } from './integrations-cards/integrations-cards.component';

@NgModule({
  imports: [
    StoreModule.forFeature(integrationsFeatureKey, integrationsReducer),
    EffectsModule.forFeature([IntegrationsEffects]),
    FormsModule,
    CommonModule,
    AngularMaterialModule,
  ],
  exports: [],
  declarations: [
    IntegrationsComponent,
    IntegrationsTableComponent,
    IntegrationsCardsComponent,
  ],
  providers: [],
})
export class IntegrationsModule {}
