import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  loadIntegrations,
  loadIntegrationsCompleted,
  approveIntegrations,
  approveIntegrationsCompleted,
  revokeIntegrations ,
  revokeIntegrationsCompleted,
} from './actions';
import { HttpClient } from '@angular/common/http';
import { Model } from '../interfaces';
import { environment } from 'src/environments/environment';

const apiUrl = (...segments: any[]) =>
  [`${environment.apiUrl}`, ...segments].join('/');

@Injectable()
export class IntegrationsEffects {
  constructor(
    private readonly actions: Actions,
    private readonly http: HttpClient,
  ) { }

  public loadIntegrations$ = createEffect(() =>
    this.actions.pipe(
      ofType(loadIntegrations),
      switchMap(() =>
        this.http
          .get<Model.AdviceSharingPermissionResponse[]>(apiUrl('api/advice-sharing-permissions/permissions'))
          .pipe(map((res) => {
            return loadIntegrationsCompleted({ integrations: res });
          }))
      ),
    ),
  );

  public approveIntegrations$ = createEffect(() =>
    this.actions.pipe(
      ofType(approveIntegrations),
      switchMap((res) =>
        this.http
          .post(apiUrl('api/advice-sharing-permissions/approve'), res )
          .pipe(map((_) => {
            return approveIntegrationsCompleted(res);
          }))
      ),
    ),
  );

  public revokeIntegrations$ = createEffect(() =>
    this.actions.pipe(
      ofType(revokeIntegrations),
      switchMap((res) =>
        this.http
          .post(apiUrl('api/advice-sharing-permissions/revoke'), res )
          .pipe(map((_) => {
            return revokeIntegrationsCompleted(res);
          }))
      ),
    ),
  );
}