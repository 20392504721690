import { combineReducers, createReducer, on } from "@ngrx/store";
import { DeclineReason } from "src/app/miscellaneous/enums/decline-reason.enum";
import { Model } from "../admin.interfaces";
import {
    adminTaskApprovedCompleted,
    adminTaskAssignedCompleted,
    editUserFixRoles,
    userEdited,
    adminTaskDeclineCompleted,
    editUserPropertyRoleUpdated,
    editUserUpdated,
    loadAdminTasksCompleted,
    propertyLockChanged,
    searchPropertiesCompleted,
    chooseDeclineReason,
    changeDeclineReasonText,
    adminSearchUsersCompleted,
    editUserPropertySearchUpdated,
    editUserProducerCodeSearchUpdated,
    adminUserSearchLoaded,
    loadUserByIdCompleted,
    persistPortalUsersSearch,
    loadUserHistoryCompleted
} from "./actions";

export const featureReducerKey = "admin";

const _adminTasksReducer = createReducer<Model.AdminTask[]>(
    [],
    on(loadAdminTasksCompleted, (state: Model.AdminTask[], { adminTasks }) => adminTasks),
    on(
        adminTaskAssignedCompleted,
        (state: Model.AdminTask[], { adminTaskId, userName, success }) => {
            const next: Model.AdminTask[] = state.slice();

            if (success) {
                const assignedAdminTaskIndex: number = next.findIndex(adminTask => adminTask.id === adminTaskId);
                const assignedAdminTask: Model.AdminTask = Object.assign({}, next[assignedAdminTaskIndex]);
    
                assignedAdminTask.assignedTo = userName;
                next[assignedAdminTaskIndex] = assignedAdminTask;
            }
            return next;
        }),
    on(adminTaskApprovedCompleted, (state: Model.AdminTask[], { adminTaskId }) => state.filter(task => task.id !== adminTaskId)),
    on(adminTaskDeclineCompleted, (state: Model.AdminTask[], { adminTaskId }) => state.filter(task => task.id !== adminTaskId)),
);

const _editUserReducer = createReducer<Model.User>(
    null,
    on(userEdited, (state: Model.User, { user }) => user),
    on(loadUserByIdCompleted, (state: Model.User, { user }) => user),
    on(editUserUpdated, (state: Model.User, { change }) => {
        return {...state, ...change};
    }),
    on(adminTaskAssignedCompleted, (state: Model.User, { userName, success }) =>
        success ? Object.assign({assignedTo: userName}, state) : state),
);

const _editUserNotificationsReducer = createReducer<Model.UserNotificationSettings>(
    null,
    on(userEdited, (state: Model.UserNotificationSettings, { }) => null),
    on(loadUserByIdCompleted, (state: Model.UserNotificationSettings, { userByIdResponse }) => userByIdResponse),
);

const _editUserPropertyRolesReducer = createReducer<Model.PropertyRole[]>(
    [],
    on(userEdited, (state: Model.PropertyRole[], { }) => []),
    on(loadUserByIdCompleted, (state: Model.PropertyRole[], { }) => []),
    on(adminTaskApprovedCompleted, (state: Model.PropertyRole[], { }) => []),
    on(searchPropertiesCompleted, (state: Model.PropertyRole[], { properties }) =>  {
        const fixedProperties: Model.PropertyRole[] =
            state.filter(propertyRole => propertyRole.locked);
        const fixedPropertyIds: number[] =
            fixedProperties.map(propertyRole => propertyRole.propertyId);
        const unfixedPropertyIds: number[] = state
            .filter(propertyRole => !propertyRole.locked)
            .map(propertyRole => propertyRole.propertyId);
        const newProperties: Model.PropertyRole[] = properties
            .filter(property => !fixedPropertyIds.includes(property.propertyId))
            .map(property => {
                const result: Model.PropertyRole = {
                    propertyId: property.propertyId,
                    propertyName: property.propertyName,
                    role: property.role,
                    locked: property.locked && !unfixedPropertyIds.includes(property.propertyId),
                };
                return result;
            });
        const result: Model.PropertyRole[] = fixedProperties.concat(newProperties);

        return result;
    }),
    on(propertyLockChanged, (state: Model.PropertyRole[], { propertyId }) =>  {
        const result: Model.PropertyRole[] = state.slice();
        const changeIndex: number = result
            .findIndex(propertyRole => propertyRole.propertyId === propertyId);

        result[changeIndex] = {
            propertyId: result[changeIndex].propertyId,
            propertyName: result[changeIndex].propertyName,
            role: result[changeIndex].role,
            locked: !result[changeIndex].locked,
        };

        return result;
    }),
    on(editUserPropertyRoleUpdated, (state: Model.PropertyRole[], { propertyId, role }) =>  {
        const result: Model.PropertyRole[] = state.slice();
        const changeIndex: number = result
            .findIndex(propertyRole => propertyRole.propertyId === propertyId);

        result[changeIndex] = {
            propertyId: result[changeIndex].propertyId,
            propertyName: result[changeIndex].propertyName,
            role,
            locked: true,
        };

        return result;
    }),
    on(editUserFixRoles, (state: Model.PropertyRole[], { }) =>  {
        const result: Model.PropertyRole[] = state.filter(propertyRole => propertyRole.locked);
        return result;
    }),
);

const _editUserHistoryTotol = createReducer<number> (
    0,
    on(userEdited, (state: number, { }) => 0),
    on(loadUserHistoryCompleted, (state: number, history) => history.total),
);

const _editUserHistory = createReducer<Model.UserByIdHistoryItem[]> (
    [],
    on(userEdited, (state: Model.UserByIdHistoryItem[], { }) => []),
    on(
        loadUserHistoryCompleted,
        (state: Model.UserByIdHistoryItem[], userById) => userById.history
            .slice()
            .sort((itemA, itemB) => itemA.created < itemB.created ? 1 : -1)),
);

const _editUserProducerCodeKnownReducer = createReducer<boolean>(
    true,
    on(searchPropertiesCompleted, (state: boolean, { producerCodeKnown }) => producerCodeKnown),
);

const _editUserPropertySearchReducer = createReducer<string>(
    null,
    on(userEdited, (state: string, { user }) => null),
    on(editUserPropertySearchUpdated, (state: string, { change }) => change),
);

const _editUserProducerCodeSearchReducer = createReducer<string>(
    null,
    on(userEdited, (state: string, { user }) => user.producerCode),
    on(editUserProducerCodeSearchUpdated, (state: string, { change }) => change),
);

const _editUserDisabledReducer = createReducer<boolean>(
    false,
    on(userEdited, (state: boolean, { disabled }) => disabled),
    on(adminTaskAssignedCompleted, (state: boolean, { success }) => !success),
    on(editUserFixRoles, (state: boolean, { }) => true),
    on(loadUserByIdCompleted, (state: boolean, { }) => false),
);

const _editUserConfirmedReducer = createReducer<boolean>(
    false,
    on(userEdited, (state: boolean, { }) => false),
    on(loadUserByIdCompleted, (state: boolean, { }) => false),
    on(editUserFixRoles, (state: boolean, { }) => true),
);

const _editAdminTaskIdReducer = createReducer<number>(
    null,
    on(userEdited, (state: number, { adminTaskId }) => adminTaskId),
    on(loadUserByIdCompleted, (state: number, { }) => null),
);

const _editDeclineReasonReducer = createReducer<DeclineReason>(
    DeclineReason.NotRecognisedProducer,
    on(chooseDeclineReason, (state: DeclineReason, { declineReason }) => declineReason),
);

const _editDeclineReasonTextReducer = createReducer<string>(
    "",
    on(changeDeclineReasonText, (state: string, { declineText }) => declineText),
);

const _editPortalUsersSearchReducer = createReducer<string>(
    null,
    on(persistPortalUsersSearch, (state: string, { searchTerm }) => searchTerm),
);

const _editPortalUsersSearchResultsReducer = createReducer<Model.UserSearchResult[]>(
    [],
    on(adminSearchUsersCompleted, (state: Model.UserSearchResult[], { userSearchResults }) => userSearchResults),
    on(adminUserSearchLoaded, (state: Model.UserSearchResult[]) => []),
);

export const reducer = combineReducers(
    {
        adminTasks: _adminTasksReducer,

        editUser: _editUserReducer,
        editUserNotifications: _editUserNotificationsReducer,
        editUserPropertyRoles: _editUserPropertyRolesReducer,
        editUserProducerCodeKnown: _editUserProducerCodeKnownReducer,
        editUserPropertySearch: _editUserPropertySearchReducer,
        editUserProducerCodeSearch: _editUserProducerCodeSearchReducer,
        editUserDisabled: _editUserDisabledReducer,
        editUserConfirmed: _editUserConfirmedReducer,
        editUserHistoryTotol: _editUserHistoryTotol,
        editUserHistory: _editUserHistory,

        editAdminTaskId: _editAdminTaskIdReducer,
        editDeclineReason: _editDeclineReasonReducer,
        editDeclineReasonText: _editDeclineReasonTextReducer,

        editPortalUsersSearch: _editPortalUsersSearchReducer,

        editPortalUsersSearchResults: _editPortalUsersSearchResultsReducer,
    },
);