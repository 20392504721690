import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { NavigationService } from '../../../services/navigation.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import {
  selectShouldDisplayDocumentsMenu,
  selectShouldDisplayOwnerMenu,
  selectShouldDisplayIntegrationMenu,
} from '../../../user';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
})
export class MoreComponent implements OnInit {
  private readonly destroy$ = new Subject();
  public shouldDisplayOwnerMenu: boolean = false;
  public shouldDisplayDocumentsMenu: boolean = false;
  public shouldDisplayIntegrationMenu: boolean = false;

  constructor(
    public readonly nav: NavigationService,
    private readonly msalInstance: MsalService,
    private readonly store: Store,
  ) {}
  
  public ngOnInit(): void {
    this.store.select(selectShouldDisplayOwnerMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((shouldDisplay) => this.shouldDisplayOwnerMenu = shouldDisplay);

    this.store.select(selectShouldDisplayDocumentsMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((shouldDisplay) => this.shouldDisplayDocumentsMenu = shouldDisplay);

    this.store.select(selectShouldDisplayIntegrationMenu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((shouldDisplay) => this.shouldDisplayIntegrationMenu = shouldDisplay);
  }

  public goNews(): void {
    this.nav.news();
  }

  public goStaff(): void {
    this.nav.userManagement();
  }

  public goDocuments(): void {
    this.nav.documents();
  }

  public goIntegrations(): void {
    this.nav.integrations();
  }

  public logout(): void {
    this.msalInstance.logoutRedirect({
      postLogoutRedirectUri: environment.webUrl,
    });
  }
}