<div class="default-container" data-cy="documents-page-shell">
  <div class="headline">
    <h2>3rd Party Data Sharing</h2>
  </div>

  <div *ngIf="unhandledIntegrations && unhandledIntegrations.length">
    <span>Data sharing request received for your account:</span>
  </div>

  <mat-card
    *ngFor="let integration of unhandledIntegrations"
    class="card-border">

    <mat-card-content>
      <div class="header">
        <div class="emphasis front-label">{{ integration.requesterName }}</div>
      </div>
      <div class="rows">
        <div>
          <span><strong> Supplier Code</strong></span>
          <div>{{ integration.anzcoSupplierId }}</div>
        </div>
        <div>
          <span><strong> Supplier Name</strong></span>
          <div>{{ integration.tradingName }}</div>
        </div>
        <div
            *ngFor="let property of integration.properties"
            class="sub-row property">
            Property: {{property.propertyName}}
        </div>
      </div>
      <div class="button-panel">
        <button class="mat-raised-button decline-button"
                (click)="deny(integration.id)">
                Deny
        </button>
        <button class="mat-raised-button mat-primary"
                (click)="approve(integration.id)">
                Approve
        </button>
      </div>
    </mat-card-content>
  </mat-card>

  <div>
    <div class="mat-elevation-z8">
      <app-integrations-table [integrations]="confirmedIntegrations"></app-integrations-table>
      <app-integrations-cards [integrations]="confirmedIntegrations"></app-integrations-cards>
      <div *ngIf="confirmedIntegrations.length === 0" class="no-results">No confirmed 3rd Party integrations found</div>
    </div>
  </div>

  <div class="footer-note">
    You can only allow an integration if you are the owner of a property, if you are missing suppliers or properties above please contact
    <a href="mailto:support@anzcoproducers.co.nz">
      support@anzcoproducers.co.nz
    </a>
  </div>
</div>